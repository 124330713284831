header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/*================== CTA STYLING ================= */

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

/*================== SOCIALS STYLING ================= */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
  font-size: 1.2rem;
}

/* .header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
} */

/*================== IMG STYLING ================= */

.me {
  background: linear-gradient(var(--color-primary), transparent);
  width: 40rem;
  height: 30rem;
  position: absolute;
  left: calc(50% - 20em);
  margin-top: 4rem;
  border-radius: 15rem;
  border-top-right-radius: 15rem;
  overflow: hidden;
}

.me > img {
  border-radius: 15rem;
}

/*================== SCROLL DOWN STYLING ================= */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 1rem;
}

/*======================= MEDIA QUERIES (MEDIUM DEVICES) ================= */

@media screen and (max-width: 1024px) {
  header {
    height: 78vh;
  }
  .me {
    width: 30rem;
    height: 30rem;
    left: calc(50% - 15rem);
  }
}

/*======================= MEDIA QUERIES (SMALL DEVICES) ================= */

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }

  .me {
    width: 25rem;
    height: 25rem;
    left: calc(50% - 12.5em);
  }

  .header__socials {
    display: none;
  }
  .scroll__down {
    display: none;
  }
}
